<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-lg-8">
          <ThisComponent>
            <h1>Добро пожаловать в каталог призов!</h1>
            <p>Вам понадобится не более одной минуты, чтобы выбрать себе подарок.</p>
            <hr>
          </ThisComponent>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AuthorizationSmsCode'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style lang="css" scoped>
  .form {
    margin-bottom: 20px;
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
